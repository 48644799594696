import React from 'react';
import styled from 'styled-components'
import SEO from '../components/SEO';
import ScreenSizes from '../styles/Responsive';
import Nav from '../components/Nav';
import { FaPhoneAlt, FaEnvelope, FaCheckCircle } from 'react-icons/fa';



export const HomePageStyle = styled.div`
  display: grid;
  grid-template-rows: min-content max-content 1fr;
`;

export const TopStyle = styled.div`
  display: grid;
  background-color: #E7EBEC;

  article{
    display: grid;
    text-align: center;
    justify-self: center;
    align-self: center;
    max-width: 55rem;
    grid-row-gap: 2.5rem;
    padding: 2rem 1rem;

    .main{
      font-size: 2.7rem;
      line-height: 140%;
      font-weight: 200;
    }

    .sub{
      font-size: 1.2rem;
      font-weight: bold;
    }

    .actions{
      justify-self: center;
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;

      @media only screen and (min-width: ${ScreenSizes.tablet}) {
        flex-direction: row;
      }
    }

    .old-link{
      text-decoration: underline;
    }

    .actions .action{

      display: inline-flex;
      align-items: center;
      letter-spacing: 1px;
      flex-grow: 0;
      border-radius: 50px 80px 50px 80px;
      padding: 1rem 2rem;
      font-size: 1.2rem;
      background-color: #000;
      color: #fff;
      transition: .2s ease-in-out;
      cursor: pointer;
      svg{margin-right: 1rem;}
    }

    .actions .action:hover{
      background-color: #444;
    }



  }

`;

export const Services = styled.div`
  display: grid;
  padding: 4rem 0;
  grid-template-columns: min-content;
  justify-content: center;
  align-content: center;
  grid-gap: 1.5rem;
  align-items: center;
  background-color: #fff;

  @media only screen and (min-width: ${ScreenSizes.tablet}) {
    grid-template-columns: min-content min-content;
  }

  span{
    display: inline-flex;
    justify-self: center;
    width: 20rem;
    font-size: 1.1rem;
    padding: 1rem 1.5rem;
    background-color: #E7EBEC;
    border-radius: .4rem;

    svg{margin-right: 1rem}

  }
`;



export default function HomePage({ data }){
  return (
    <HomePageStyle>
      <SEO/>
      <Nav/>
      <TopStyle>
   
        <article>

          <p className="main"> Hi, <br/>we are creating a new interface to give you a smoother and more personalized experience</p>
          <p className="sub">CONTACT US TO ACCESS OUR SERVICES</p>

          <div className="actions">
            <span className="action">
              <FaPhoneAlt/>+234-903-000-9002
            </span>
            <span className="action">
              <FaEnvelope/> hello@hego3rip.com
            </span>
          </div>

          <a className="old-link" href="/old.php">Old website</a>
        
        </article>
      </TopStyle>

      <Services>
        <span><FaCheckCircle/> Website development</span>
        <span><FaCheckCircle/> Web App development</span>
        <span><FaCheckCircle/> API  design</span>
        <span><FaCheckCircle/> Web payment Integration</span>
        <span><FaCheckCircle/> UI/UX design</span>
        <span><FaCheckCircle/> Branding</span>
      </Services>

    </HomePageStyle>
  )
}