import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/img/hego3rip_logo.png'
import ScreenSizes from '../styles/Responsive';
// import controlNav from '../utils/controlNav';

const NavStyles = styled.nav`
  
  display: grid;
  position: sticky;
  grid-column: 1 / -1;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  align-items: center;
  background-color: #E7EBEC;

  .top{
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 3rem;
    padding: 1rem var(--side-pad-xs);
    box-shadow: var(--box-shadow-1);
    
    @media only screen and (min-width: ${ScreenSizes.tablet}) {
      grid-template-columns: max-content 1fr 1fr; // add one more column on laptop
    }
  
    .brand-logo{
      display: flex;
      align-items: center;
      
      .logo{
        max-height: 2.5rem;
        margin-right: 1.5rem; // right space between the logo and links
        @media only screen and (min-width: ${ScreenSizes.tablet}) {
          max-height: 3rem;
        }
      }
      
      .name{
        font-weight: bold;
        font-size: 1.5rem;
        letter-spacing: 2px;
        color: var(--color-primary);
        @media only screen and (min-width: ${ScreenSizes.laptop}) {
          font-size: 1.7rem;
        }
      }
   }

    .links{
      display: none; // grid in tab
      justify-content: end;
      align-items: center;
      grid-auto-flow: column;
      grid-column-gap: 3.5rem;
      font-size: 1.1rem;
      letter-spacing: 1px;
      a:hover{
        color: var(--color-primary-light) ;
      }

      @media only screen and (min-width: ${ScreenSizes.tablet}) {
        display: grid;
      }
    }
    
    .call-to-action{
      display: none; //flex in tab
      justify-self: right;
      align-items: center;
      @media only screen and (min-width: ${ScreenSizes.tablet}) {
        display: flex;
      }
      
      .sign-in{
        font-size: 1.1rem;
        letter-spacing: 1px;
        cursor: pointer;
        &:hover{
          color: var(--color-primary) ;
        }
      }
      
      .register{
        display: flex;
        margin-left: 1.5rem; // space between signin
        cursor: pointer;
        background-color: var(--color-primary-dark) ;
        padding: 1rem 1.5rem;
        color: var(--color-white);
        border-radius: 4px;
        letter-spacing: 1px;
        &:hover{
          background-color: var(--color-primary) ;
        }
      }
    }
    
    //only shows on mobile
    .menu{
      justify-self: right;
      align-items: center;
      font-size: 2.5rem;
      
      @media only screen and (min-width: ${ScreenSizes.tablet}) {
        display: none
      }
    }
  }

  .dropdown{
    grid-column: 1 / -1 ;
    max-height: 0;
    overflow: hidden;
    transition: .8s ease-out;
    
    .link{
      display: block;
      padding: 1.2rem;
      border-bottom: 1px solid var(--color-grey-2);
    }

    .signin{
      display: block;
      color: var(--color-primary);
      padding: 1.2rem;
      border-bottom: 1px solid var(--color-grey-2);
    }

    .signup{
      display: block;
      padding: .8rem;
      background-color: var(--color-primary);
      text-align: center;
      color: white;
      border-radius: .4rem;
      margin: .5rem .5rem 1.5rem .5rem;
    }
  }
  .dropdown.open{
    max-height: 500px;
  }
  `;

function handleNav(e){
  e.preventDefault(); // prevent default action
  const nav = document.getElementById('navigation'); // get the navigation container
  const dropDown = nav.querySelector('.dropdown'); // get the drop down section of the navigation
  dropDown.classList.toggle('open'); // toggle the open class to change its height
}

export default function Nav() {
  return (
    <NavStyles id='navigation'>

      <div className="top">
        <Link to='/' className='brand-logo'>
          <img className="logo" src={logo} alt="Afe Babalola University Alumni Logo"/>
        </Link>
      </div>

    </NavStyles>
  );
}